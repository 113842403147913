import get from 'lodash/get';
import set from 'lodash/set';
import merge from 'lodash/merge';
import unset from 'lodash/unset';
import SnackbarUtils from 'components/ui-base/SnackbarUtils';
import Translation from 'components/data/Translation';
import cloneDeep from 'components/template-designer/utils/cloneDeep';
import Template, { DesignerSettings, State, View } from '../types/template.type';
import { getTemplateData } from './data.helpers';
import Format, { FormatSet, FormatSortOrder } from '../types/format.type';
import LayerProperties from '../types/layerProperties.type';
import FormatProperties from '../types/formatProperties.type';
import FrameType from '../types/frameTypes.type';
import { DEFAULT_ZOOM_LEVEL, MAX_VISIBILE_FORMATS } from '../constants';
import { TimelineHelpers } from './timeline.helpers';
import { InfiniteViewerHelpers } from './infinite-viewer.helpers';
import Layer from '../types/layer.type';
import TemplateDesignerStore, { MultiModel } from '../data/template-designer-store';
import { FeatureHelpers } from './features.helpers';
import { Features } from '../types/features.type';

class FormatHelpers {
    private static lastFormatClicked: Format['key'] = 'general';

    /**
     * Add new formats.
     * @param formats - Formats to add.
     */
    static addFormats = (formats: Format[]): void => {
        const currentFormats = getTemplateData<Template['formats']>('formats');
        const frameTypes = getTemplateData<Template['frameTypes']>('frameTypes');
        const displayFormats = getTemplateData<State['displayFormats']>('state.displayFormats');
        const formatOrder = getTemplateData<State['formatOrder']>('state.formatOrder');
        const maxVisibibleFormats = FormatHelpers.getMaxVisibleFormats();
        const newDisplayFormats = [...displayFormats, ...formats.map((formatToAdd) => formatToAdd.key)];
        const newFormats = [...currentFormats, ...formats];

        const changes: MultiModel = [['state.selectedFormats', ['general']]];

        // Prepare layer properties for the new formats.
        formats.forEach((formatToAdd) => {
            const formatKey = formatToAdd.key;

            frameTypes.forEach((frameType) => {
                changes.push([`layerProperties.${formatKey}.${frameType.key}`, { properties: {} }]);
            });
        });

        if (newDisplayFormats.length > maxVisibibleFormats) {
            newDisplayFormats.splice(0, newDisplayFormats.length - maxVisibibleFormats);
            SnackbarUtils.warning(Translation.get('general.messages.limitedDisplayFormats', 'template-designer', { formatCount: maxVisibibleFormats }));
        }

        const sortedFormats = FormatHelpers.sortFormats(formatOrder, true, newFormats);

        TemplateDesignerStore.save([...changes, ['formats', sortedFormats], ['state.displayFormats', newDisplayFormats]]);

        TimelineHelpers.seekTo(0);
    };

    /**
     * Delete format or array of formats.
     * Delete all format specific styling for these formats.
     * @param formatKey - Format key to delete or array of format keys to be deleted.
     */
    static deleteFormat = (formatKeys: Format['key'] | Format['key'][]): void => {
        const frameType = getTemplateData<View['frameType']>('view.frameType');
        const layerProperties = getTemplateData<Template['layerProperties']>('layerProperties');
        const formats = getTemplateData<Template['formats']>('formats');
        const displayFormats = getTemplateData<State['displayFormats']>('state.displayFormats');
        let largeMedia = getTemplateData<State['largeMedia']>('state.largeMedia');

        let newFormats = formats;
        let newDisplayFormats = displayFormats;

        if (typeof formatKeys === 'string') {
            formatKeys = [formatKeys];
        }

        // Array of formats to be deleted.
        formatKeys.forEach((formatKey) => {
            newFormats = newFormats.filter((format) => format.key !== formatKey);
            newDisplayFormats = newDisplayFormats.filter((format) => format !== formatKey);
            delete layerProperties[formatKey];
        });

        largeMedia = largeMedia?.filter((media) => !formatKeys.includes(media.format));

        /**
         * Check if there is 1 format left.
         * Merge format styling to general setup.
         */
        if (newFormats.length === 1) {
            const formatKey = newFormats[0].key;

            // Merge format overwrites into general.
            const generalFormatProperties: FormatProperties = cloneDeep(get(layerProperties, `general.${frameType}.properties`, {}));
            const formatProperties: FormatProperties = cloneDeep(get(layerProperties, `${formatKey}.${frameType}.properties`, {}));
            const newGeneralFormatProperties: FormatProperties = merge({}, generalFormatProperties, formatProperties);
            set(layerProperties, `general.${frameType}.properties`, newGeneralFormatProperties);
            set(layerProperties, `${formatKey}.${frameType}.properties`, {});

            // Merge layer overwrites into general.
            const layerOverwriteProperties: LayerProperties = cloneDeep(get(layerProperties, `${formatKey}.${frameType}`, {}));

            Object.keys(layerOverwriteProperties).forEach((layerKey) => {
                if (layerKey === 'properties') return;

                const generalLayerProperties: LayerProperties = cloneDeep(get(layerProperties, `general.${frameType}.${layerKey}`, {}));
                let layerOverwrites: LayerProperties = layerOverwriteProperties[layerKey];

                //TODO: DELETE AFTER WE CREATED A WRAPPER
                if (layerOverwrites.properties && Array.isArray(layerOverwrites.properties)) {
                    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
                    // @ts-ignore
                    layerOverwrites = {};
                }

                const newGeneralLayerProperties: LayerProperties = merge({}, generalLayerProperties, layerOverwrites);
                set(layerProperties, `general.${frameType}.${layerKey}`, newGeneralLayerProperties);
                unset(layerProperties, `${formatKey}.${frameType}.${layerKey}`);
            });
        }

        const changes: MultiModel = [
            ['state.selectedFormats', ['general'], false],
            ['state.displayFormats', newDisplayFormats],
            ['layerProperties', layerProperties],
            ['formats', newFormats],
            ['state.largeMedia', largeMedia]
        ];

        if (newFormats.length === 0) {
            InfiniteViewerHelpers.setZoomLevel(DEFAULT_ZOOM_LEVEL);
            InfiniteViewerHelpers.scrollTo(0, 0);
        }

        TemplateDesignerStore.save(changes);

        TimelineHelpers.seekTo(0);
    };

    /**
     * Sort formats by title, size or position.
     * @param sort - Sort by title, size or position.
     * @param ascending - Sort ascending or descending.
     */
    static sortFormats = (sort: FormatSortOrder, isGetter?: boolean, formats?: Template['formats']): Format[] | void => {
        if (formats === undefined) formats = getTemplateData<Template['formats']>('formats');

        /**
         * Sort formats by title ascending.
         * @param formats - Formats to sort.
         * @returns Sorted formats.
         */
        const sortByTitleAscending = (formats: Format[]): Format[] => {
            return cloneDeep(formats).sort((a, b) => a.title.localeCompare(b.title));
        };

        /**
         * Sort formats by title descending.
         * @param formats - Formats to sort.
         * @returns Sorted formats.
         */
        const sortByTitleDescending = (formats: Format[]): Format[] => {
            return cloneDeep(formats).sort((a, b) => b.title.localeCompare(a.title));
        };

        /**
         * Sort formats by surface area ascending.
         * @param formats - Formats to sort.
         * @returns Sorted formats.
         */
        const sortBySurfaceAreaAscending = (formats: Format[]): Format[] => {
            return cloneDeep(formats).sort((a, b) => {
                const surfaceAreaA = a.width * a.height;
                const surfaceAreaB = b.width * b.height;
                return surfaceAreaA - surfaceAreaB;
            });
        };

        /**
         * Sort formats by surface area descending.
         * @param formats - Formats to sort.
         * @returns Sorted formats.
         */
        const sortBySurfaceAreaDescending = (formats: Format[]): Format[] => {
            return cloneDeep(formats).sort((a, b) => {
                const surfaceAreaA = a.width * a.height;
                const surfaceAreaB = b.width * b.height;
                return surfaceAreaB - surfaceAreaA;
            });
        };

        /**
         * Sort formats by canvas order.
         * @param formats - Formats to sort.
         * @returns Sorted formats.
         */
        const sortByCanvasOrder = (formats: Format[]): Format[] => {
            // Sort formats by y coordinate and then by x coordinate
            const sortedFormats = cloneDeep(formats).sort((a, b) => {
                if (a.x === undefined || a.y === undefined || b.x === undefined || b.y === undefined) return 0;

                if (a.y === b.y) {
                    return a.x - b.x; // Sort by x coordinate if y coordinates are the same
                }

                return a.y - b.y; // Otherwise, sort by y coordinate
            });

            return sortedFormats;
        };

        const sortOrder: [string, FormatSortOrder] = ['state.formatOrder', sort];

        const sortedFormats = (() => {
            switch (sort) {
                case FormatSortOrder.AlphabeticalAsc:
                    return sortByTitleAscending(formats);
                case FormatSortOrder.AlphabeticalDes:
                    return sortByTitleDescending(formats);
                case FormatSortOrder.SizeAsc:
                    return sortBySurfaceAreaAscending(formats);
                case FormatSortOrder.SizeDes:
                    return sortBySurfaceAreaDescending(formats);
                case FormatSortOrder.Position:
                    return sortByCanvasOrder(formats);
            }
        })();

        if (isGetter) {
            return sortedFormats;
        }

        TemplateDesignerStore.save([['formats', sortedFormats], sortOrder]);
    };

    /**
     * Adds a custom format to the template.
     * @param format - Custom format to add.
     */
    static addCustomFormat = (format: Format): void => {
        const customFormats = getTemplateData<DesignerSettings['customFormats']>('designerSettings.customFormats');
        TemplateDesignerStore.save(['designerSettings.customFormats', [...customFormats, format]]);
    };

    /**
     * Edit custom format in template.
     * @param format - Custom format to edit.
     */
    static editCustomFormat = (format: Format): void => {
        const formats = getTemplateData<Template['formats']>('formats');
        const customFormats = getTemplateData<DesignerSettings['customFormats']>('designerSettings.customFormats');

        TemplateDesignerStore.save([
            ['designerSettings.customFormats', customFormats.map((f) => (f.key === format.key ? format : f))],
            ['formats', formats.map((f) => (f.key === format.key ? format : f))]
        ]);
    };

    /**
     * Delete custom format from template.
     * @param formatKey - Format key to delete.
     */
    static deleteCustomFormat = (formatKey: Format['key']): void => {
        const formats = getTemplateData<Template['formats']>('formats');
        const customFormats = getTemplateData<DesignerSettings['customFormats']>('designerSettings.customFormats');
        const newFormats = formats.filter((format) => format.key !== formatKey);
        const newCustomFormats = customFormats.filter((format) => format.key !== formatKey);

        TemplateDesignerStore.save([
            ['designerSettings.customFormats', newCustomFormats],
            ['formats', newFormats]
        ]);
    };

    /**
     * Set the selected and display formats to the given format.
     * @param formatKey - Format key to focus.
     */
    static setSelectedFormats = (formatKey: Format['key'], options?: { ctrlKey?: boolean; shiftKey?: boolean }): void => {
        let selectedFormats = getTemplateData<State['selectedFormats']>('state.selectedFormats');
        const exists = selectedFormats.includes(formatKey);

        if (formatKey === 'general' && exists && selectedFormats.length === 1) return;

        if (options?.ctrlKey && formatKey !== 'general') {
            selectedFormats = this.ctrlClicked(selectedFormats, formatKey);
            this.lastFormatClicked = formatKey;
        } else if (options?.shiftKey && formatKey !== 'general') {
            selectedFormats = this.shiftClicked(selectedFormats, formatKey);
        } else {
            selectedFormats = [formatKey];
            this.lastFormatClicked = formatKey;
        }

        if (!selectedFormats.length) {
            selectedFormats = ['general'];
        }

        TemplateDesignerStore.save(['state.selectedFormats', selectedFormats], { saveToHistory: false });
    };

    /**
     * Set the display formats to the given format.
     * @param formatKeys - Format key or array of format keys to set display formats.
     * @param show - Show or hide the format.
     */
    static setDisplayFormats = (formatKeys: Format['key'][], show?: boolean): void => {
        const oldDisplayFormats = getTemplateData<State['displayFormats']>('state.displayFormats');
        const selectedFormats = getTemplateData<State['selectedFormats']>('state.selectedFormats');
        const maxVisibibleFormats = FormatHelpers.getMaxVisibleFormats();

        let newDisplayFormats: State['displayFormats'] = oldDisplayFormats;

        formatKeys.forEach((formatKey) => {
            const exists = newDisplayFormats.includes(formatKey);

            if ((exists && show === undefined) || show === false) {
                newDisplayFormats = newDisplayFormats.filter((newDisplayFormat) => newDisplayFormat !== formatKey);

                const inSelectedFormats = selectedFormats.includes(formatKey);
                if (inSelectedFormats) {
                    this.setSelectedFormats(formatKey, { ctrlKey: false, shiftKey: false });
                }
            } else {
                newDisplayFormats = [...newDisplayFormats, formatKey];
            }
        });

        if (newDisplayFormats.length > maxVisibibleFormats && newDisplayFormats.length > oldDisplayFormats.length) {
            return SnackbarUtils.info(
                Translation.get('general.messages.limitedDisplayFormats', 'template-designer', {
                    formatCount: maxVisibibleFormats
                })
            );
        }

        TemplateDesignerStore.save(['state.displayFormats', newDisplayFormats]);
    };

    /**
     * Set the display formats to the given format.
     * @param formatKey - Format key or array of format keys to set display formats.
     */
    static setDisplayFormatsFocus = (formatKeys: Format['key'] | Format['key'][], show: boolean): void => {
        const formats = getTemplateData<Template['formats']>('formats');

        if (typeof formatKeys === 'string') {
            formatKeys = [formatKeys];
        }

        let newDisplayFormats: State['displayFormats'] = [];

        if (show) {
            formatKeys.forEach((formatKey) => {
                newDisplayFormats.push(formatKey);
            });
        } else {
            newDisplayFormats = formats.map((format) => format.key).filter((formatKey) => !formatKeys.includes(formatKey));
        }

        TemplateDesignerStore.save(['state.displayFormats', newDisplayFormats]);
    };

    /**
     * Set the selected and display formats to the given format.
     * @param formatKey - Format key to focus.
     */
    static focusFormat = (formatKey: Format['key']): void => {
        const formats = getTemplateData<Template['formats']>('formats');
        const displayFormats = getTemplateData<State['displayFormats']>('state.displayFormats');

        let newDisplayFormats;
        let selectedFormats;

        if (formatKey === 'general' || (displayFormats.length === 1 && displayFormats[0] === formatKey)) {
            newDisplayFormats = [...formats.map((format) => format.key)];
            selectedFormats = ['general'];
        } else {
            newDisplayFormats = [formatKey];
            selectedFormats = [formatKey];
        }

        TemplateDesignerStore.save(
            [
                ['state.selectedFormats', selectedFormats],
                ['state.displayFormats', newDisplayFormats]
            ],
            { saveToHistory: false }
        );
    };

    /**
     * Check which format should be updated.
     * If only one format exists, always update general.
     * If multiple formats exists, update the selected format. This also can be general.
     * @param formats - Formats to check. If not given, get the formats from the template.
     * @returns The format key to update.
     */
    static currentFormat = (formats?: Template['formats'], selectedFormats?: State['selectedFormats']): Format['key'] => {
        if (formats === undefined) formats = getTemplateData<Template['formats']>('formats', { clone: false });
        if (formats.length === 1) return 'general';
        if (selectedFormats === undefined) selectedFormats = getTemplateData<State['selectedFormats']>('state.selectedFormats', { clone: false });
        return selectedFormats[0];
    };

    /**
     * Adds or removes format depening on the ctrl key.
     * @param selectedFormats - Current selected formats.
     * @param clickedFormat - Format that is clicked.
     * @returns New selected formats.
     */
    private static ctrlClicked = (selectedFormats: State['selectedFormats'], clickedFormat: Format['key']): State['selectedFormats'] => {
        if (selectedFormats.includes('general')) {
            // Remove general because general can not be selected if another format is selected/
            selectedFormats.splice(selectedFormats.indexOf('general'), 1);
        }

        if (selectedFormats.includes(clickedFormat)) {
            if (selectedFormats.length > 1) {
                // If clickedFormat in the list and not the only one remove.
                selectedFormats.splice(selectedFormats.indexOf(clickedFormat), 1);
            }
        } else {
            // If clickedFormat is not in the list add.
            selectedFormats = [...selectedFormats, clickedFormat];
        }

        return selectedFormats;
    };

    /**
     * Adds or removes format depening on the shift key.
     * @param selectedFormats - Current selected formats.
     * @param clickedFormat - Format that is clicked.
     * @returns New selected formats.
     */
    private static shiftClicked = (selectedFormats: State['selectedFormats'], clickedFormat: Format['key']): State['selectedFormats'] => {
        const formats = getTemplateData<Template['formats']>('formats');
        const formatList = formats.map((format) => format.key);

        if (selectedFormats.includes('general')) {
            //Remove general because general can not be selected if another format is selected
            selectedFormats.splice(selectedFormats.indexOf('general'), 1);
        }

        const currentSelectedIndex = formatList.indexOf(clickedFormat);
        const lastNormalClickedIndex = this.lastFormatClicked ? formatList.indexOf(this.lastFormatClicked) : undefined;

        if (lastNormalClickedIndex === -1 || lastNormalClickedIndex === undefined) return selectedFormats;

        if (currentSelectedIndex > lastNormalClickedIndex) {
            //Get items between last not shift clicked and last current index
            return formatList.slice(lastNormalClickedIndex, currentSelectedIndex + 1);
        }

        if (currentSelectedIndex < lastNormalClickedIndex) {
            return formatList.slice(currentSelectedIndex, lastNormalClickedIndex + 1);
        }

        return selectedFormats;
    };

    /**
     * Get the format with the smallest size.
     * @returns The format with the smallest size.
     */
    static getSmallestFormat = (): Format => {
        const formats = getTemplateData<Template['formats']>('formats');
        return formats.reduce((acc, cur) => {
            if (!acc.width || acc.width > cur.width) acc = cur;
            if (!acc.height || acc.height > cur.height) acc = cur;
            return acc;
        }, {} as Format);
    };

    /**
     * Get the format with the largest size.
     * @returns The format with the largest size.
     */
    static getLargestFormat = (): Format => {
        const formats = getTemplateData<Template['formats']>('formats');
        return formats.reduce((acc, cur) => {
            if (!acc.width || acc.width < cur.width) acc = cur;
            if (!acc.height || acc.height < cur.height) acc = cur;
            return acc;
        }, {} as Format);
    };

    /**
     * Get the format with the largest width.
     * @returns The format with the largest width.
     */
    static getLargestFormatWidth = (): Format => {
        const formats = getTemplateData<Template['formats']>('formats');
        return formats.reduce((acc, cur) => {
            if (!acc.width || acc.width < cur.width) acc = cur;
            return acc;
        }, {} as Format);
    };

    /**
     * Get the format with the largest height.
     * @returns The format with the largest height.
     */
    static getLargestFormatHeight = (): Format => {
        const formats = getTemplateData<Template['formats']>('formats');
        return formats.reduce((acc, cur) => {
            if (!acc.height || acc.height < cur.height) acc = cur;
            return acc;
        }, {} as Format);
    };

    /**
     * Get the format with the most square size.
     * @param formats - Formats to compare.
     * @returns The format with the most square size.
     */
    static getMostSquareFormat = (formats?: Format[]): Format | undefined => {
        if (formats === undefined) formats = getTemplateData<Template['formats']>('formats', { clone: false });

        let mostSquareFormat: Format | undefined = formats[0];
        let smallestSquarenessScore = Infinity;

        // Iterate through the array of objects
        for (const format of formats) {
            // Calculate the squareness score (absolute difference between width and height)
            const squarenessScore = Math.abs(format.width - format.height);

            // Check if this object has a smaller squareness score than the current smallest
            if (squarenessScore < smallestSquarenessScore) {
                smallestSquarenessScore = squarenessScore;
                mostSquareFormat = format;
            }
        }

        return mostSquareFormat;
    };

    /**
     * Get the format with the closest size.
     * @param format - Format to compare.
     * @param formats - Formats to compare with.
     * @returns The format with the closest size or null.
     */
    static getSimularFormat = (format: Format, formats: Template['formats'] | undefined = getTemplateData<Template['formats']>('formats')): Format | null => {
        /**
         * Calculate the distance between two formats.
         * @param formatA - Format to compare.
         * @param formatB - Format to compare with.
         * @returns The distance between the two formats.
         */
        function calculateDistance(formatA: Format, formatB: Format) {
            // Check for dimensions. Do not suggest vertical formats for horizontal and vice versa.
            if ((formatA.width > formatA.height && formatB.width < formatB.height) || (formatA.width < formatA.height && formatB.width > formatB.height)) {
                return Infinity;
            }

            // Check different in aspect ratio
            const dimensionDiff = Math.abs(formatA.width / formatA.height - formatB.width / formatB.height) / 2;

            // Calculate the size difference between the both formats, and make it relative to the width and height.
            const widthDifference = Math.abs((formatA.width - formatB.width) / formatA.width);
            const heightDifference = Math.abs((formatA.height - formatB.height) / formatA.height);

            // Combine aspect ratio with size difference in a formula.
            return widthDifference + heightDifference + dimensionDiff;
        }

        let closestFormat: Format | null = null;
        let closestDistance = Infinity;

        /**
         * Loop through all formats and check which one is the closest.
         */
        for (const currentFormat of formats) {
            const distance = calculateDistance(currentFormat, format);

            if (distance < closestDistance) {
                closestDistance = distance;
                closestFormat = currentFormat;
            }
        }

        return closestFormat;
    };

    /**
     * Filter the formats that have overwrites.
     * @param frameType - The frame type to check.
     * @returns The formats that has overwrites.
     */
    static getFormatsWithOverwrites = (frameType: FrameType): Format[] => {
        const formats = getTemplateData<Template['formats']>('formats');

        /**
         * Check if the object has properties.
         * @param object - The object to check.
         * @returns True if the object has properties, otherwise false.
         */
        const hasProperties = (object: object | null | undefined) => {
            if (!object) return false;

            return Object.keys(object).some((key) => {
                if (Array.isArray(object[key])) return object[key].length > 0;
                if (typeof object[key] === 'object') return hasProperties(object[key]);
                return !!object[key];
            });
        };

        return formats.filter((format) => {
            const formatOverwrites = getTemplateData<Template['layerProperties']['format']['frame']>(`layerProperties.${format.key}.${frameType.key}`);
            if (!formatOverwrites) return false;
            if (Array.isArray(formatOverwrites)) return false;
            if (!formatOverwrites.properties) return false;
            if (Object.keys(formatOverwrites.properties).length === 0 && !hasProperties(formatOverwrites)) return false;
            return true;
        });
    };

    /**
     * Changes the name of a format.
     * @param formatKey Key of the format to change
     * @param newName New name for the format
     */
    static changeFormatName = (formatKey: Format['key'], newName: string): void => {
        const formats = getTemplateData<Format[]>('formats');
        const newFormatIndex = formats.findIndex((format) => format.key === formatKey);
        formats[newFormatIndex].title = newName;
        TemplateDesignerStore.save(['formats', formats], { saveToHistory: false });
    };

    /**
     * Get format sets from the formats.
     * @returns The format sets.
     */
    static getFormatSets = (formats: Template['formats']): Format['sets'] => {
        const sets: Format['sets'] = [];

        formats.forEach((format) => {
            if (!format.sets) format.sets = [];
            format.sets.forEach((set) => {
                if (!sets.includes(set)) {
                    sets.push(set);
                }
            });
        });

        sets.sort();

        return sets;
    };

    /**
     * Finds formats with the given format sets.
     * @param formatSets - Formats sets to show.
     */
    static setDisplayFormatsFromFormatSets = (formatSets: Format['sets']): void => {
        const formats = getTemplateData<Template['formats']>('formats');

        let newDisplayFormats: State['displayFormats'] = [];

        /**
         * If there are format sets, find the new display formats.
         * Otherwise every format is the new display format.
         */
        if (formatSets.length) {
            const unsetLabel = Translation.get('general.labels.unset', 'template-designer');

            newDisplayFormats = formats
                .filter((format) => {
                    if (format.sets.length === 0) return formatSets.includes(unsetLabel);
                    return format.sets.find((set) => formatSets.includes(set));
                })
                .map((format) => format.key);
        } else {
            newDisplayFormats = formats.map((format) => format.key);
        }

        TemplateDesignerStore.save([
            ['state.selectedFormats', ['general']],
            ['state.displayFormats', newDisplayFormats]
        ]);
    };

    /**
     * Get format sets from the formats for the export.
     * @returns The format sets.
     */
    static getExportFormatSets = (formats: Template['formats']): FormatSet[] => {
        const formatsHasSets = formats.some((format) => format.sets.length > 0);

        if (!formatsHasSets) return [];

        return formats.reduce((all, format) => {
            // If the format has no sets, add it to the unset set.
            if (format.sets.length === 0) {
                const label = Translation.get('general.labels.unset', 'template-designer');
                const unset = all.find((item) => item.title === label);

                if (unset) {
                    unset.formats.push(format);
                } else {
                    all.push({
                        title: label,
                        formats: [format]
                    });
                }
            }

            format.sets.forEach((set) => {
                const formatSet = all.find((item) => item.title === set);

                if (!formatSet) {
                    all.push({
                        title: set,
                        formats: [format]
                    });
                } else {
                    formatSet.formats.push(format);
                }

                return all;
            });

            return all;
        }, [] as FormatSet[]);
    };

    /**
     * Check if the format has overwrites.
     * @param formatKey - Key of the format to check
     * @returns True if the format has overwrites
     */
    static layerHasOverwrites = (formatKey: Format['key'] | Format['key'][], layerKey: Layer['key']): boolean => {
        const layerProperties = getTemplateData<Template['layerProperties']>('layerProperties');
        const frameType = getTemplateData<View['frameType']>('view.frameType');

        if (typeof formatKey === 'string') {
            formatKey = [formatKey];
        }

        return formatKey.some((format) => {
            // Check if selected layers has overwrites.
            const layerFormatProperties = get(layerProperties, `${format}.${frameType}.${layerKey}`, {});
            return (
                layerFormatProperties &&
                Object.keys(layerFormatProperties).length > 0 &&
                Object.keys(layerFormatProperties).some(
                    (property) => layerFormatProperties[property] && Object.keys(layerFormatProperties[property]).length > 0
                )
            );
        });
    };

    /**
     * Check if the format has overwrites.
     * @param formatKey - Key of the format to check
     * @returns True if the format has overwrites
     */
    static formatHasOverwrites = (formatKey: Format['key']): boolean => {
        const layerProperties = getTemplateData<Template['layerProperties']>('layerProperties');
        const frameType = getTemplateData<View['frameType']>('view.frameType');
        const formatStyle = get(layerProperties, `${formatKey}.${frameType}.properties`, {});

        if (Object.keys(formatStyle).length > 0) return true;

        const formatStyling = get(layerProperties, `${formatKey}.${frameType}`, {});
        if (!formatStyling) return false;

        // Check every layer if it has overwrites.
        for (const layerKey of Object.keys(formatStyling)) {
            if (layerKey === 'properties') continue;

            const layerFormatProperties = get(layerProperties, `${formatKey}.${frameType}.${layerKey}`, {});
            if (Object.keys(layerFormatProperties).length === 0) return false;

            for (const key of Object.keys(layerFormatProperties)) {
                const layerProperties = get(layerFormatProperties, `${key}`, {});
                if (layerProperties && Object.keys(layerProperties).length > 0) return true;
            }
        }

        return false;
    };

    /**
     * Gets the format name by the format key.
     * @param formatKey The format key
     * @returns The format name
     */
    static getFormatName = (formatKey: Format['key']): string => {
        if (formatKey === 'general') return Translation.get('general.labels.generalSetup', 'template-designer');
        const format = this.getFormatByKey(formatKey);
        if (!format) return formatKey;
        return format.title;
    };

    /**
     * Get the format by the format key.
     * @param formatKey - The format key to get the format for.
     * @returns The format.
     */
    static getFormatByKey = (formatKey: Format['key'], formats?: Format[]): Format | null => {
        if (formats === undefined) formats = getTemplateData<Template['formats']>('formats');
        return formats.find((format) => format.key === formatKey) ?? null;
    };

    /**
     * Get the maximum number of visible formats.
     * @returns The maximum number of visible formats.
     */
    static getMaxVisibleFormats = (): number => {
        const allowUnlimitedVisibleFormats = FeatureHelpers.hasFeature(Features.ALLOW_UNLIMITED_VISIBLE_FORMATS);
        if (allowUnlimitedVisibleFormats) return Infinity;
        return MAX_VISIBILE_FORMATS;
    };
}

export default FormatHelpers;
