import set from 'lodash/set';
import { Selected } from '../components/dynamic-layer-input-dialog';
import Template, { FeedMappingStructure, View } from '../types/template.type';
import { getTemplateData } from './data.helpers';
import { ConfigHelpers } from './config.helpers';
import TemplateDesignerStore from '../data/template-designer-store';
import { convertAfterEffectsAttribute } from '../components/adobe/utils/convertAfterEffectsAttribute';
import { convertInDesignAttribute } from '../components/adobe/utils/convertInDesignAttribute';
import { Attributes } from '../types/attribute.type';
import FrameType from '../types/frameTypes.type';
import Layer from '../types/layer.type';
import { AdobeHelpers } from './adobe.helpers';

class FeedMappingHelpers {
    /**
     * Check if the layer and attribute are linked to the input.
     * @param feedMapping - The feed mapping object from the template.
     * @param layer - The layer.
     * @param attribute - The attribute from the input.
     * @returns - Returns true if the layer and attribute are linked to the input.
     */
    static isLinkedToInput = (feedMapping: Template['dataVariables']['frameType'], layerKey: Layer['key'], attribute: Attributes): boolean => {
        if (!feedMapping || !layerKey) return false;
        const feedMappingKeys = Object.keys(feedMapping);
        const feedMappingKeyExists = feedMappingKeys.includes(layerKey);
        const feedMappingAttributeExists = feedMapping[layerKey] && Object.keys(feedMapping[layerKey]).includes(attribute);
        return feedMappingKeyExists && feedMappingAttributeExists;
    };

    /**
     * Add a feed to the frame.
     * @param feedMappingStructure - The feed mapping structure.
     * @param frameType - The frame type. Default is the current frame type.
     */
    static addFeed = (feedMappingStructure: FeedMappingStructure, frameType?: View['frameType']): void => {
        if (frameType === undefined) frameType = getTemplateData<View['frameType']>('view.frameType');
        TemplateDesignerStore.save([`templateSetup.feedMappingStructure.${frameType}`, feedMappingStructure]);
    };

    /**
     * Add feed mapping to the selected layers.
     * @param selected - The selected elements.
     * @param frameType - The frame type. Default is the current frame type.
     */
    static addFeedMapping = (selection: Selected, frameType?: View['frameType']): void => {
        const templateType = getTemplateData<Template['templateData']['type']>('templateData.type');
        let feedMapping = getTemplateData<Template['dataVariables']>('dataVariables');
        const isAdobeTemplate = ConfigHelpers.isAdobeTemplate(templateType);

        // If feedMapping is not set or is an array, initialize it as an empty object
        if (!feedMapping || Array.isArray(feedMapping)) {
            feedMapping = {} as Template['dataVariables'];
        }

        // Default frameType if not provided
        if (frameType === undefined) {
            frameType = getTemplateData<View['frameType']>('view.frameType');
        }

        // Initialize the items for Adobe templates only
        let itemsInLayers = {};
        if (isAdobeTemplate) {
            const items = (() => {
                if (templateType === 'dynamicAfterEffects') {
                    return getTemplateData<Template['compositions']>('compositions', { clone: false });
                }

                if (templateType === 'dynamicInDesign') {
                    return getTemplateData<Template['pages']>('pages', { clone: false });
                }

                return [];
            })();

            const allLayers = AdobeHelpers.getAllLayers();
            itemsInLayers = AdobeHelpers.getItemInLayers(allLayers, items, undefined);
        }

        Object.keys(selection).forEach((layerKey) => {
            const attributes = selection[layerKey];

            attributes.forEach((attribute) => {
                let newFrameType = frameType;
                let newAttribute = attribute;

                if (isAdobeTemplate) {
                    newFrameType = 'main';

                    if (templateType === 'dynamicAfterEffects') {
                        newAttribute = convertAfterEffectsAttribute(attribute) as Attributes;
                    }

                    if (templateType === 'dynamicInDesign') {
                        newAttribute = convertInDesignAttribute(attribute) as Attributes;
                    }

                    // Handle Adobe-specific logic: use itemsInLayers for Adobe templates.
                    const items = itemsInLayers[layerKey];
                    if (items) {
                        items.forEach((item) => {
                            const key = `${item.key}.${layerKey}`;

                            if (!feedMapping[newFrameType] || Array.isArray(feedMapping[newFrameType])) {
                                feedMapping[newFrameType] = {};
                            }

                            feedMapping[newFrameType][key] = {
                                ...feedMapping[newFrameType][key],
                                [newAttribute]: newAttribute
                            };
                        });
                    }
                } else {
                    // Handle non-Adobe (normal templates) feed mapping.
                    if (!feedMapping[newFrameType] || Array.isArray(feedMapping[newFrameType])) {
                        feedMapping[newFrameType] = {};
                    }

                    feedMapping[newFrameType][layerKey] = {
                        ...feedMapping[newFrameType][layerKey],
                        [newAttribute]: ''
                    };
                }
            });
        });

        TemplateDesignerStore.save(['dataVariables', feedMapping]);
    };

    /**
     * Delete mapping name from the layer.
     * @param layerKey - The layer key.
     * @param attribute - The attribute.
     * @param frameType - The frame type. Default is the current frame type.
     */
    static deleteFeedMapping = (itemKey: string, layerKey: Layer['key'], attribute: Attributes, frameType?: FrameType['key']): void => {
        if (frameType === undefined) frameType = getTemplateData<View['frameType']>('view.frameType');

        const isAdobeTemplate = ConfigHelpers.isAdobeTemplate();

        const entry = (() => {
            if (!isAdobeTemplate) {
                return layerKey;
            }

            // If the layer key is the same as the item, then we don't have the item as this had been changed with the new Adobe plugin update.
            if (layerKey === itemKey) {
                return layerKey;
            }

            return `${itemKey}.${layerKey}`;
        })();

        const frameFeedMapping = getTemplateData<Template['dataVariables']>(`dataVariables.${frameType}`);

        if (!isAdobeTemplate) {
            delete frameFeedMapping[entry][attribute];
        } else {
            Object.keys(frameFeedMapping).forEach((entry) => {
                if (entry.includes(layerKey)) {
                    delete frameFeedMapping[entry][attribute];
                }
            });
        }

        /**
         * Remove the layer if it has no more attributes.
         * Also check for if it is an array. Empty objects get converted to arrays.
         */
        Object.keys(frameFeedMapping).forEach((entry) => {
            if (Array.isArray(frameFeedMapping[entry]) || Object.keys(frameFeedMapping[entry]).length === 0) {
                delete frameFeedMapping[entry];
            }
        });

        TemplateDesignerStore.save([`dataVariables.${frameType}`, frameFeedMapping]);
    };

    /**
     * Check if the frame has feed mapping.
     * @param dataVariables - The feed mapping object from the template.
     * @param frameType - The frame type.
     * @returns Returns true if the frame has a feed mapping.
     */
    static hasFrameFeedMapping = (dataVariables?: Template['dataVariables'], frameType?: View['frameType']): boolean => {
        if (dataVariables === undefined) dataVariables = getTemplateData<Template['dataVariables']>('dataVariables');
        if (frameType === undefined) frameType = getTemplateData<View['frameType']>('view.frameType');

        const currentFeedMapping = dataVariables[frameType];
        return !!(currentFeedMapping && !!Object.keys(currentFeedMapping).length);
    };

    /**
     * Change the attribute mapping name.
     * @param itemKey - The item key.
     * @param layerKey - The layer key.
     * @param attribute - The attribute.
     * @param mappingName - The mapping name.
     * @param frameType - The frame type. Default is the current frame type.
     */
    static changeAttributeMappingName = (
        itemKey: string,
        layerKey: Layer['key'],
        attribute: Attributes,
        mappingName: string,
        frameType?: FrameType['key']
    ): void => {
        if (frameType === undefined) frameType = getTemplateData<View['frameType']>('view.frameType');

        const frameFeedMapping = getTemplateData<Template['dataVariables']>(`dataVariables.${frameType}`);

        const key = (() => {
            if (ConfigHelpers.isAdobeTemplate()) {
                return [`${itemKey}.${layerKey}`, ...attribute.split('.')];
            }

            return [layerKey, ...attribute.split('.')];
        })();

        set(frameFeedMapping, key, mappingName);

        TemplateDesignerStore.save([`dataVariables.${frameType}`, frameFeedMapping]);
    };

    /**
     * Get the item key based on the entry.
     * If it is an Adobe template, the item key is the first part of the entry.
     * @param key - The key of the entry.
     * @param isAdobeTemplate - Whether the template is an Adobe template. Default is the current template type.
     * @returns The item key.
     */
    static getItemKey = (key: string, isAdobeTemplate?: boolean): string => {
        if (isAdobeTemplate === undefined) isAdobeTemplate = ConfigHelpers.isAdobeTemplate();

        if (!isAdobeTemplate) {
            return key;
        }

        const parts = key.split('.');
        return parts[0];
    };

    /**
     * Get the layer key based on the entry.
     * If it is an Adobe template, the layer key is the last part of the entry.
     * @param key - The key of the entry.
     * @param isAdobeTemplate - Whether the template is an Adobe template. Default is the current template type.
     * @returns The layer key.
     */
    static getLayerKey = (key: string, isAdobeTemplate?: boolean): string => {
        if (isAdobeTemplate === undefined) isAdobeTemplate = ConfigHelpers.isAdobeTemplate();

        if (!isAdobeTemplate) {
            return key;
        }

        const parts = key.split('.');
        return parts[parts.length - 1];
    };
}

export { FeedMappingHelpers };
